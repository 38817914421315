import React from "react"
import cx from 'classnames'

import { Image } from 'src/components/image'

export interface BadgesHeroProps {
  data: {
    title: string,
    image: {},
    mobileImage: {}
    badges: []
  }
}

export const BadgesHero = ({ data }: BadgesHeroProps) => {
  const { title, image, badges, mobileImage } = data
  return (
    <React.Fragment>
      <div className={cx(`pr bb bc p1 df jcs y badge__hero aie`)}>
        {mobileImage && (<Image className='x pa top left x hide-800 y' imageId={mobileImage.imageId} alt={title} />)}
        <Image className='x pa top left x show--800 y' imageId={image.imageId} alt={title} />
        <div className="outer show--800 color--white tc pr z1 mxa pt6 pb4--800 container--600  p1 tl x">
          <h1 className='s80'>{title}</h1>
        </div>
        {badges.map((badge, i) => (
          <div className={cx('badge__hero-b pa color--white df jcc aic', `badge--${i}`)}>
            <span className='db caps tc pr z1 founders  s18'>{badge}</span>
            {i === 0 && (
              <svg className='pa x left top right bottom' width="165" height="151" viewBox="0 0 165 151" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0.854023 67.3294C25.151 53.3921 43.3795 29.3269 48.8564 0.0037675C72.9466 14.0278 102.862 17.7443 130.905 7.74227C130.698 35.7035 142.301 63.4275 164.951 82.8064C140.655 96.7436 122.426 120.809 116.949 150.132C92.8589 136.108 62.9433 132.391 34.9004 142.394C35.1652 114.348 23.42 86.6505 0.854023 67.3294Z" fill="#130C80"/>
              </svg>              
            )}
          </div>
        ))}
      </div>
      <div className="outer hide--800 pr z1 mxa p4 tc bb bc tl x mb2">
        <h1 className='italic'>{title}</h1>
      </div>
    </React.Fragment>
  )
}

export default BadgesHero